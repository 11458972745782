import React from 'react'
import { Helmet } from 'react-helmet'

const Header = ({ metaTitle, metaDescription, slug, scriptTag }) => {
  return (
    <Helmet>
        <title>{metaTitle}</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-S2SVRK6LJB"></script>
        <script type="text/javascript">
        {`    window.dataLayer = window.dataLayer || [];
                function gtag(){
                dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-S2SVRK6LJB');`}
        </script>
        <meta name="description" content={metaDescription} />
        <meta name='robots' content='index, follow' />
        <link rel="canonical" href={`https://zaavia.net${slug}`} />
        <meta name='X-Frame-Options' content='Denied' />      
        <meta name='X-Content-Type-Option' content='No sniff' />      
        <meta name='Referrer-Policy' content='No-referrer' />      
        <meta name='Strict-Transport-Security' content='max-age=31536000, includeSubDomains, preload' />
        {/* <meta http-equiv="Permissions-Policy" content="picture-in-picture=('https://www.youtube.com/embed/mhZy0nBZH5M')" /> */}
        <meta httpEquiv="Content-Security-Policy" content="default-src 'self' 'unsafe-inline' https://fonts.gstatic.com/; img-src 'self' data: http://res.cloudinary.com https://www.googletagmanager.com https://i.ytimg.com https://b.sf-syn.com/ https://topfirms.co; connect-src 'self' http://localhost:* ws://localhost:* https://www.google-analytics.com https://www.google.com/recaptcha/api2/ https://pr9vi9ux6i.execute-api.ap-southeast-1.amazonaws.com/prod/; style-src-elem 'unsafe-inline'  https://fonts.googleapis.com; script-src-elem 'self' 'unsafe-eval' 'unsafe-inline' https://*.googletagmanager.com https://www.google.com/recaptcha/api.js  https://www.gstatic.com/recaptcha/releases/hbAq-YhJxOnlU-7cpgBoAJHb/recaptcha__en.js https://b.sf-syn.com https://assets.goodfirms.co https://widget.trustpilot.com http://widget.trustpilot.com; script-src 'unsafe-eval'; frame-src  https://www.google.com http://www.youtube.com/ https://widget.goodfirms.co/ https://widget.trustpilot.com/" />
        {scriptTag ? 
            <script type="application/ld+json">
                {`
                    ${scriptTag}
                `}
            </script>
          :''
        }
        
    </Helmet>
)
}

export default Header